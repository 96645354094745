import { Backdrop, CircularProgress } from "@mui/material";
import { Fragment } from "react";
import { useAppStateAPI } from "./AppStateAPI";
// import { useAppStateAPI } from "../../common/AppStateAPI";


const Preloader: React.FC = () => {

    const { showPreloader } = useAppStateAPI();
    const animationType = localStorage.getItem('animation');

    return (
        <Fragment>
            {showPreloader &&
                <Backdrop
                    sx={{ color: '#ff0023', zIndex: "9999" }}
                    open={showPreloader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </Fragment>
    )
}

export default Preloader;