import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react"

interface FormParameters {
    contents?:string,
    handleOpen?:any,
    openModal?:any
    handleClose?:any
}

const SimpleModal: React.FC<FormParameters>= ({contents,handleOpen, openModal,handleClose}) => {
    const [open, setOpen] = useState(false);

    useEffect(()=>{                
        if (openModal === true) {
            setOpen(true);
        }else{
            setOpen(false);

        }
        
    },[openModal])

    const handleCloses = () => {
        setOpen(false);
        handleClose(false)
    };
    return (
        <div>
            <Dialog
                open={open}
                maxWidth="xs"
                className="dialog-box"
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" className="model-title">
                <div className="modal-box-title">
                    <p className="m-0">Remark</p>
                    <p className="m-0" onClick={handleClose}><i className="fa fa-times cursor-pointer font-style" aria-hidden="true"></i></p>
                </div>
                </DialogTitle>
                <hr/>
               
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                       <p className="box-content m-0"> {contents}</p>
                    </DialogContentText>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleCloses} autoFocus>
                        Agree
                    </Button>
                </DialogActions> */}
            </Dialog>
        </div>
    );
}

export default SimpleModal;