import React, { useEffect, useState, Component, Fragment } from "react";
import Header from "../../common/Header";



const Cities: React.FC = () => {
    return (
        <div id="layout-wrapper">
            <Header />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Cities</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);" style={{ color: "#90929E" }}>Trustmony</a></li>
                                            <li className="breadcrumb-item">Masters</li>
                                            <li className="breadcrumb-item active">Cities</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header col-xl-12 w-100">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className="row">
                                                    <div className="col-xl-2 mt-4"><h4 className="card-title">Search :</h4></div>
                                                    <div className="col-xl-4"><form className="app-search card-title "><div className="position-relative"><input type="text" className="form-control" /></div></form></div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 text-end">
                                                <div className="row">
                                                    <div className="col-xl-4"><form className="app-search card-title "><div className="position-relative"><input type="text" placeholder="From Date" className="form-control" /></div></form></div>
                                                    <div className="col-xl-1 mt-4"><h4 className="card-title">TO</h4></div>
                                                    <div className="col-xl-4"><form className="app-search card-title "><div className="position-relative"><input type="text" placeholder="To Date" className="form-control" /></div></form></div>
                                                    <div className="col-xl-3 mt-3"><button type="button" className="btn btn-dark waves-effect waves-light"><i className="fas fa-plus-circle"></i> &nbsp;Add</button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-rep-plugin">
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <table id="tech-companies-1" className="table table table-bordered">
                                                    <thead className="city-table">
                                                        <tr>
                                                            <th>Sr.No</th>
                                                            <th data-priority="1">Created Date</th>
                                                            <th data-priority="3">City Name</th>
                                                            <th data-priority="1">State</th>
                                                            <th colSpan={3} data-priority="3">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th>01</th>
                                                            <td>21-08-2022</td>
                                                            <td>Mumbai</td>
                                                            <td>Maharashtra</td>
                                                            <td className="city-action-rable-row">
                                                                <i className="fas fa-edit"></i>
                                                            </td>
                                                            <td className="city-action-rable-row">
                                                                <i className="fas fa-eye"></i>
                                                            </td>
                                                            <td className="city-action-rable-row">
                                                                <div className="form-check form-switch mb-3" dir="ltr">
                                                                    <input type="checkbox" className="form-check-input" id="customSwitchsizesm" checked />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>02</th>
                                                            <td>22-08-2022</td>
                                                            <td>Thane</td>
                                                            <td>Maharashtra</td>
                                                            <td className="city-action-rable-row">
                                                                <i className="fas fa-edit"></i>
                                                            </td>
                                                            <td className="city-action-rable-row">
                                                                <i className="fas fa-eye"></i>
                                                            </td>
                                                            <td className="city-action-rable-row">
                                                                <div className="form-check form-switch mb-3" dir="ltr">
                                                                    <input type="checkbox" className="form-check-input" id="customSwitchsizesm" checked />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>03</th>
                                                            <td>23-08-2022</td>
                                                            <td>Kankavli</td>
                                                            <td>Maharashtra</td>
                                                            <td className="city-action-rable-row">
                                                                <i className="fas fa-edit"></i>
                                                            </td>
                                                            <td className="city-action-rable-row">
                                                                <i className="fas fa-eye"></i>
                                                            </td>
                                                            <td className="city-action-rable-row">
                                                                <div className="form-check form-switch mb-3" dir="ltr">
                                                                    <input type="checkbox" className="form-check-input" id="customSwitchsizesm" checked />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>04</th>
                                                            <td>24-08-2022</td>
                                                            <td>Alibaug</td>
                                                            <td>Maharashtra</td>
                                                            <td className="city-action-rable-row">
                                                                <i className="fas fa-edit"></i>
                                                            </td>
                                                            <td className="city-action-rable-row">
                                                                <i className="fas fa-eye"></i>
                                                            </td>
                                                            <td className="city-action-rable-row">
                                                                <div className="form-check form-switch mb-3" dir="ltr">
                                                                    <input type="checkbox" className="form-check-input" id="customSwitchsizesm" checked />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Cities;