
import React from 'react';

const Footer: React.FC = () => {
    return (
        <div className='container-flude footer-style'>
            <p className='footer-text mb-0 p-2'>
                © 2021-2022 Trust Capital Pvt Ltd. | CIN: U72123MH1234PTC123456
            </p>
        </div>
    );
}

export default Footer;